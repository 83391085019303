<template>
    <modal ref="modalProgressGris" :titulo="tituloModal">
        <div v-if="showFecha" class="row mx-0 px-5 mb-3">
            <div class="col px-0 text-general f-17 f-500">
                {{ formatearFecha(fecha[0]) }} - {{ formatearFecha(fecha[1]) }}
            </div>
        </div>
        <div v-for="(data, index) in datos" :key="index" class="row mx-0 px-5 mb-3 align-items-center">
            <img v-if="imgProducto" :src="data.foto" class="border br-12 obj-cover" width="50" height="50" />
            <div class="col px-2">
                <p class="text-general f-15">
                    {{ data.nombre }}
                </p>
                <p class="text-general2 f-14">
                    {{ tipoCantidad ? agregarSeparadoresNumero(data.valor, 1) : separadorNumero(data.valor) }}
                </p>
                <div class="row mx-0" style="height:10px;">
                    <div class="bg-gris br-20" :style="`width:${data.promedio}%`" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import comparativa from "~/services/teams/comparativa"


export default {
    props: {
        imgProducto: {
            type: Boolean,
            default: false
        },
        teams: {
            type: Array,
            default: () => []
        },
        showFecha:{
            type: Boolean,
            default: false
        },
        fecha: {
            type: Array,
            default: () => []
        },
    },
    data(){
        return {
            tipo: 1,
            datos: [],
            tipoCantidad: true,
        }
    },
    computed:{
        tituloModal(){
            let message = 'Valor del inventario'
            switch (this.tipo){
            case 1:
                message = 'Valor del inventario'
                break;
            case 2:
                message = 'Equipos calificados de los últ. 12 meses'
                break;
            case 3:
                message = 'Cedis calificados de los últ. 12 meses'
                break;
            case 4:
                message = 'Leecheros con mayor cantidad de ventas'
                break;
            case 5:
                message = 'Ventas'
                break;
            case 6:
                message = 'Ticket promedio'
                break;
            case 7:
                message = 'Categorías más vendidas'
                break;
            case 8:
                message = 'Productos más vendidos'
                break;
            case 9:
                message = 'Productos más con mayor presencia en pedidos'
                break;
            
            default:
                break;
            }

            return message
        },
        params(){
            const teams = this.teams.map(el => el.id).toString()
            const params = {
                teams,
                limited: 0
            }

            if(this.showFecha){
                params['fecha'] = this.fecha
            }

            return params
        }
    },
    methods: {
        async toggle(tipo){
            this.tipo = tipo
            this.tipoCantidad = ![1, 5, 6, 7, 8].includes(this.tipo)
            await this.buscarDatos()
            this.$refs.modalProgressGris.toggle();
        },
        async buscarDatos(){
            switch (this.tipo){
            case 1:
                await this.getDataInventario()
                break;
            case 2:
                await this.getDataCalificacion()
                break;
            case 3:
                await this.getDataCalificacionCedis()
                break;
            case 4:
                await this.getDataLeecherosMasVentas()
                break;
            case 5:
                await this.getDataVentas()
                break;
            case 6:
                await this.getDataTicket()
                break;
            case 7:
                await this.getDataCategorias()
                break;
            case 8:
                await this.getDataProductosMasVendidos()
                break;
            case 9:
                await this.getDataProductosMasPresencia()
                break;
            }
        },
        async getDataInventario(){
            try {
                const { data } = await comparativa.inventario(this.params);
                const total = _.sumBy(data, 'inventario')
                
                this.datos = data.map(el => {
                    el.valor = el.inventario
                    el.promedio = total > 0 ? ((el.inventario * 100) / total) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataCalificacion(){
            try {
                const { data } = await comparativa.calificacionUltimoAnio(this.params);
                this.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataCalificacionCedis(){
            try {
                const { data } = await comparativa.calificacionCedisUltimoAnio(this.params);
                this.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataLeecherosMasVentas(){
            try {
                const { data } = await comparativa.leecherosMasVentas(this.params);

                const total = _.sumBy(data, 'valor')

                this.datos = data.map(el => {
                    el.promedio = total > 0 ? ((el.valor * 100) / total) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataVentas(){
            try {
                const { data } = await comparativa.ventasCantidadPedidos(this.params);

                const total = _.sumBy(data, 'suma')

                this.datos = data.map(el => {
                    el.valor = el.suma
                    el.promedio = total > 0 ? ((el.valor * 100) / total) : 0
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataTicket(){
            try {
                const { data } = await comparativa.ticketPromedio(this.params);


                this.datos = data.map(el => {
                    el.valor = el.suma
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataCategorias(){
            try {
                const { data } = await comparativa.categorias(this.params);

                const total = _.sumBy(data, 'total')


                this.datos = data.map(el => {
                    el.valor = el.total

                    el.promedio = total > 0 ? ((el.valor * 100) / total) : 0

                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataProductosMasVendidos(){
            try {
                const { data } = await comparativa.productosVenta(this.params);

                const total = _.sumBy(data, 'valor')


                this.datos = data.map(el => {

                    el.promedio = total > 0 ? ((el.valor * 100) / total) : 0

                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataProductosMasPresencia(){
            try {
                const { data } = await comparativa.productosCantidad(this.params);

                const total = _.sumBy(data, (el) => Number(el.valor))

                this.datos = data.map(el => {

                    el.promedio = total > 0 ? ((el.valor * 100) / total) : 0

                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        
    }
}
</script>